<template>
    <div class="aq-list">
        <div class="aq-unlogin hide">
            <img src="../../../assets/imgs/pc/img_kongbai@2x.png" alt="">
            <div class="aq-unlogin-notice">
                您還沒有<a href="javascript:;" class="do-login">登錄/</a><a href="javascript:;" class="do-register">註冊</a>，當前無法顯示您的收藏
            </div>
        </div>
        <div class="aq-item">
            <div class="question-item">
                <div class="question-item-head">
                    <img src="../../../assets/imgs/pc/img_yonghu@2x.png" alt="" class="question-avatar">
                    <div class="question-item-head-r">
                        <div class="question-user-info">
                            <div class="name-w">用戶<span class="user-name">MXS7528</span>提問:</div>
                            <span class="gender">女</span>
                            <span class="age">100歲</span>
                        </div>
                        <h3 class="question-item-title"><span class="q-category">#妇科</span>後腦經常出汗感到困擾 ？</h3>
                    </div>
                </div>
                <div class="question-item-content">
                    <p class="question-item-content-wrapper">問題描述：近年来，随着人工智能、大数据、云计算等技术在教育领域的应用落地，技术正在作为一种强大的驱动力，逐渐渗透到教、学、练、考、管的各个环节，教育这个古老的行业，也被注入了新生机。2020年，一场疫情让教育信息化、数字化迎来了突飞猛进的发展，在教育部“停课不停学”的政策号召下，一时间，”在线教学“、”网课“、“OMO模式”等成为全民口中的热词。两会期间，众多教育界学者和企业大佬们将“教育新基建”纳入了两会提案中，鼓励发展智慧教育、“互联网+教育”、“5G+教育”等教育新模式。</p>
                    <a href="javascript:;" class="link-all">#查看全部</a>
                </div>
            </div>
            <div class="answer-item">
                <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                <div class="answer-item-r">
                    <div class="answer-item-head">
                        醫師
                        <span class="doctor-name">趙亞平</span>
                        <span class="create-time">7/1/2020 8:03:55 PM</span>
                    </div>
                    <p class="answer-item-content">有可能是感冒未清,建議去找中醫作進一步診斷。</p>
                </div>
            </div>
            <div class="answer-list-w hide">
                <div class="answer-list">
                    <div class="answer-item">
                        <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                        <div class="answer-item-r">
                            <div class="answer-item-head">
                                醫師
                                <span class="doctor-name">趙亞平</span>
                                <span class="create-time">7/1/2020 8:03:55 PM</span>
                            </div>
                            <p class="answer-item-content">有可能是感冒未清,建議去找中醫作進一步診斷。</p>
                        </div>
                    </div>
                    <div class="answer-item">
                        <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                        <div class="answer-item-r">
                            <div class="answer-item-head">
                                醫師
                                <span class="doctor-name">胡图图</span>
                                <span class="create-time">7/1/2020 8:03:55 PM</span>
                            </div>
                            <p class="answer-item-content">網上無法知道你的病因，建議盡快求醫。</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aq-operate">
                <div class="btn-sea-all">5條回復</div>
                <div class="btn-un-save-aq">取消收藏</div>
            </div>
        </div>
        <div class="aq-item">
            <div class="question-item">
                <div class="question-item-head">
                    <img src="../../../assets/imgs/pc/img_yonghu@2x.png" alt="" class="question-avatar">
                    <div class="question-item-head-r">
                        <div class="question-user-info">
                            <div class="name-w">用戶<span class="user-name">MXS7528</span>提問:</div>
                            <span class="gender">女</span>
                            <span class="age">100歲</span>
                        </div>
                        <h3 class="question-item-title"><span class="q-category">#妇科</span>後腦經常出汗感到困擾 ？</h3>
                    </div>
                </div>
                <div class="question-item-content">
                    <p class="question-item-content-wrapper">問題描述：近年来，随着人工智能、大数据、云计算等技术在教育领域的应用落地，技术正在作为一种强大的驱动力，逐渐渗透到教、学、练、考、管的各个环节，教育这个古老的行业，也被注入了新生机。2020年，一场疫情让教育信息化、数字化迎来了突飞猛进的发展，在教育部“停课不停学”的政策号召下，一时间，”在线教学“、”网课“、“OMO模式”等成为全民口中的热词。两会期间，众多教育界学者和企业大佬们将“教育新基建”纳入了两会提案中，鼓励发展智慧教育、“互联网+教育”、“5G+教育”等教育新模式。</p>
                    <a href="javascript:;" class="link-all">#查看全部</a>
                </div>
            </div>
            <div class="answer-item">
                <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                <div class="answer-item-r">
                    <div class="answer-item-head">
                        醫師
                        <span class="doctor-name">趙亞平</span>
                        <span class="create-time">7/1/2020 8:03:55 PM</span>
                    </div>
                    <p class="answer-item-content">有可能是感冒未清,建議去找中醫作進一步診斷。</p>
                </div>
            </div>
            <div class="answer-list-w hide">
                <div class="answer-list">
                    <div class="answer-item">
                        <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                        <div class="answer-item-r">
                            <div class="answer-item-head">
                                醫師
                                <span class="doctor-name">趙亞平</span>
                                <span class="create-time">7/1/2020 8:03:55 PM</span>
                            </div>
                            <p class="answer-item-content">有可能是感冒未清,建議去找中醫作進一步診斷。</p>
                        </div>
                    </div>
                    <div class="answer-item">
                        <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                        <div class="answer-item-r">
                            <div class="answer-item-head">
                                醫師
                                <span class="doctor-name">胡图图</span>
                                <span class="create-time">7/1/2020 8:03:55 PM</span>
                            </div>
                            <p class="answer-item-content">網上無法知道你的病因，建議盡快求醫。</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aq-operate">
                <div class="btn-sea-all">5條回復</div>
                <div class="btn-un-save-aq">取消收藏</div>
            </div>
        </div>
        <div class="aq-item">
            <div class="question-item">
                <div class="question-item-head">
                    <img src="../../../assets/imgs/pc/img_yonghu@2x.png" alt="" class="question-avatar">
                    <div class="question-item-head-r">
                        <div class="question-user-info">
                            <div class="name-w">用戶<span class="user-name">MXS7528</span>提問:</div>
                            <span class="gender">女</span>
                            <span class="age">100歲</span>
                        </div>
                        <h3 class="question-item-title"><span class="q-category">#妇科</span>後腦經常出汗感到困擾 ？</h3>
                    </div>
                </div>
                <div class="question-item-content">
                    <p class="question-item-content-wrapper">問題描述：近年来，随着人工智能、大数据、云计算等技术在教育领域的应用落地，技术正在作为一种强大的驱动力，逐渐渗透到教、学、练、考、管的各个环节，教育这个古老的行业，也被注入了新生机。2020年，一场疫情让教育信息化、数字化迎来了突飞猛进的发展，在教育部“停课不停学”的政策号召下，一时间，”在线教学“、”网课“、“OMO模式”等成为全民口中的热词。两会期间，众多教育界学者和企业大佬们将“教育新基建”纳入了两会提案中，鼓励发展智慧教育、“互联网+教育”、“5G+教育”等教育新模式。</p>
                    <a href="javascript:;" class="link-all">#查看全部</a>
                </div>
            </div>
            <div class="answer-item">
                <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                <div class="answer-item-r">
                    <div class="answer-item-head">
                        醫師
                        <span class="doctor-name">趙亞平</span>
                        <span class="create-time">7/1/2020 8:03:55 PM</span>
                    </div>
                    <p class="answer-item-content">有可能是感冒未清,建議去找中醫作進一步診斷。</p>
                </div>
            </div>
            <div class="answer-list-w hide">
                <div class="answer-list">
                    <div class="answer-item">
                        <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                        <div class="answer-item-r">
                            <div class="answer-item-head">
                                醫師
                                <span class="doctor-name">趙亞平</span>
                                <span class="create-time">7/1/2020 8:03:55 PM</span>
                            </div>
                            <p class="answer-item-content">有可能是感冒未清,建議去找中醫作進一步診斷。</p>
                        </div>
                    </div>
                    <div class="answer-item">
                        <img src="../../../assets/imgs/pc/img_nvyis@2x.png" alt="" class="answer-avatar">
                        <div class="answer-item-r">
                            <div class="answer-item-head">
                                醫師
                                <span class="doctor-name">胡图图</span>
                                <span class="create-time">7/1/2020 8:03:55 PM</span>
                            </div>
                            <p class="answer-item-content">網上無法知道你的病因，建議盡快求醫。</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="aq-operate">
                <div class="btn-sea-all">5條回復</div>
                <div class="btn-un-save-aq">取消收藏</div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
    
    }
</script>

<style lang="scss" scoped>
    /* 提问的问题项 start */
    .aq-list {
        display: flex;
        flex-direction: column;
    }

    .aq-list .aq-item .question-item {
        margin-top: 24px;
        position: relative;
    }

    .aq-list .aq-item .question-item .question-item-head {
        display: flex;
        flex-direction: row;
    }

    .aq-list .aq-item .question-item .question-item-head-r {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .aq-list .aq-item .question-item .question-avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .aq-list .aq-item .question-item .question-user-info {
        flex: 1;
        display: flex;
        flex-direction: row;
        height: 13px;
        line-height: 13px;
        margin-bottom: 8px;
        width: 630px;
        font-size: 14px;
        font-weight: 400;
        color: #969696;
    }

    .aq-list .aq-item .question-item .question-user-info > .gender {
        margin-left: 20px;
    }

    .aq-list .aq-item .question-item .question-user-info > .age {
        margin-left: 8px;
    }

    .aq-list .aq-item .question-item .question-user-info > .add-time {
        margin-left: 20px;
    }

    .aq-list .aq-item .question-item .question-item-title {
        flex: 1;
        font-size: 18px;
        font-weight: bold;
        color: #231F20;
        height: 18px;
        line-height: 24px;
        text-align: left;
        text-overflow: ellipsis;
    }

    .aq-list .aq-item .question-item .question-item-title > .q-category {
        color: #FF7800;
        margin-right: 8px;
    }

    .question-item-content {
        line-height: 16px;
        font-weight: 400;
        color: #969696;
        margin: 16px 0px 0px 41px;
        width: 598px;
        display: flex;
        flex-direction: row;
        font-size: 16px;
        color: #969696;
    }

    .aq-list.my-aq .aq-item .question-item .question-item-content {
        margin-top: 13px;
        flex-direction: column;
    }

    .question-item-content > .sub-q {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-top: 17px;
    }

    .question-item-content > .sub-q .sub-q-hint,
    .question-item-content > .sub-q > .sub-q-title {
        font-size: 16px;
        line-height: 24px;
        color: #1B2122;
        font-weight: 400;
    }

    .question-item-content > .sub-q > .sub-q-title {
        font-weight: bold;
        margin-left: 8px;
    }

    .question-item-content > .sub-q .sub-q-hint {
        position: relative;
        padding-left: 24px;
    }

    .question-item-content > .sub-q .sub-q-hint::after {
        position: absolute;
        content: '';
        left: 0px;
        bottom: 1px;
        width: 15px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_fankui@2x.png);
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }

    .question-item-content > .link-all {
        height: 16px;
        line-height: 16px;
        font-weight: 400;
        color: #36C4D0;
    }

    .question-item-content > .question-item-content-wrapper {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        line-height: 22px;
        transition: all .2s linear;
    }

    .aq-list.my-aq .aq-item .question-item .question-item-content > .question-item-content-wrapper {
        flex: 1;
        text-overflow: unset;
        overflow: unset;
        text-overflow: unset;
        display: unset;
        -webkit-line-clamp: unset;
        -webkit-box-orient: unset;
    }

    /* 提问的问题项 end */


    /* 医师回答的答案 start */
    .aq-list .aq-item {
        padding-bottom: 26px;
        margin: 10px 12px 0px 12px;
        border-bottom: 1px solid #E8E8E8;
    }

    .aq-list .answer-list-w {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        margin-top: 30px;
        max-height: unset;
        transition: all .5s cubic-bezier(0, 1, 0.5, 1);
    }

    .aq-list.my-aq .answer-list-w {
        margin-top: 28px;
    }

    .aq-list .answer-list-w.hide {
        margin-top: 0px;
        max-height: 0px;
    }

    .aq-list .answer-list-w .answer-list {
        transform: translateY(0);
        transition: all .5s cubic-bezier(0, 1, 0.5, 1);
    }

    .aq-list .answer-list-w.hide .answer-list {
        transform: translateY(-100%);
    }

    .aq-list .answer-item {
        display: flex;
        flex-direction: row;
        margin-top: 24px;
    }

    .aq-list .answer-item:nth-child(1) {
        margin-top: 0px;
    }

    .aq-list .answer-item .answer-avatar {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 8px;
    }

    .aq-list .answer-item .answer-item-r {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .aq-list .answer-item .answer-item-r .answer-item-head {
        flex: 1;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        line-height: 14px;
    }

    .aq-list .answer-item .answer-item-r .answer-item-content {
        flex: 1;
        font-weight: 400;
        font-size: 16px;
        color: #231F20;
        line-height: 24px;
        margin-top: 7px;
    }

    .aq-list .aq-operate {
        margin: 16px 0px 0px 42px;
        display: flex;
        flex-direction: row;
        position: relative;
    }

    .aq-list .aq-operate .btn-unshift-q,
    .aq-list .aq-operate .btn-sea-all {
        height: 27px;
        line-height: 27px;
        position: relative;
        padding-left: 39px;
        padding-right: 13px;
        font-weight: 400;
        color: #008C98;
        font-size: 14px;
        background-color: #DCF7FB;
        border-radius: 8px;
        display: inline-block;
        cursor: pointer;
        margin-right: 45px;
    }

    .aq-list.my-aq .aq-operate .btn-unshift-q,
    .aq-list.my-aq .aq-operate .btn-sea-all {
        margin-right: 20px;
    }

    .aq-list .aq-operate .btn-unshift-q {
        background-color: #FFE3D5;
        color: #72240B;
    }

    .aq-list .aq-operate .btn-unshift-q::after,
    .aq-list .aq-operate .btn-sea-all::after {
        content: '';
        position: absolute;
        left: 11px;
        top: 6px;
        width: 17px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_huifu@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .aq-list .aq-operate .btn-unshift-q::after {
        background-image: url(../../../assets/imgs/pc/img_zhuiwen@2x.png);
    }

    .aq-list .aq-operate .btn-sea-all.extrend {
        padding-left: 0;
        color: transparent;
        margin-right: 0px;
        width: 104px;
        padding: 0;
    }

    .aq-list .aq-operate .btn-sea-all.extrend::after {
        content: '收起回復';
        position: absolute;
        left: 0px;
        top: 0px;
        width: 90px;
        height: auto;
        font-size: 14px;
        line-height: 27px;
        color: #36C4D0;
        font-weight: bold;
        background-image: none;
        text-align: right;
    }

    .aq-list .aq-operate .btn-sea-all.extrend::before {
        content: '';
        position: absolute;
        left: 11px;
        top: 6px;
        width: 17px;
        height: 15px;
        background-image: url(../../../assets/imgs/pc/img_huifu@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .aq-list .aq-operate .btn-save-aq {
        position: absolute;
        left: 176px;
        top: 0px;
        height: 27px;
        line-height: 27px;
        padding-left: 37px;
        padding-right: 13px;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        cursor: pointer;

        display: none;
    }

    .aq-list .aq-operate .btn-save-aq > span {
        position: absolute;
        font-weight: 600;
        color: #FF8000;
        font-size: 14px;
        opacity: 0;
        right: 0px;
        transform: translateY(100%);
        transition: all .5s ease-in-out;
    }

    .aq-list .aq-operate .btn-save-aq.saved {
        position: absolute;
        left: 176px;
        top: 0px;
        height: 27px;
        line-height: 27px;
        padding-left: 39px;
        padding-right: 13px;
        font-weight: 400;
        color: #FF8000;
        font-size: 14px;
        cursor: pointer;
    }

    .aq-list .aq-operate .btn-save-aq::after {
        content: '';
        position: absolute;
        left: 10px;
        top: 6px;
        width: 17px;
        height: 14px;
        background-image: url(../../../assets/imgs/pc/img_shoucang@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .aq-list .aq-operate .btn-save-aq.saved::after {
        background-image: url(../../../assets/imgs/pc/img_shoucangdao@2x.png);
    }

    .aq-list .aq-operate .btn-un-save-aq {
        float: right;
        height: 27px;
        line-height: 27px;
        padding-left: 5px;
        padding-right: 5px;
        margin-right: 12px;
        font-weight: 400;
        color: #969696;
        font-size: 14px;
        cursor: pointer;
        text-decoration: underline;
    }
    /* 医师回答的答案 end */

   /* 我的咨询 start */
    .aq-list .aq-unlogin.hide {
        display: none;
    }

    .aq-list .aq-unlogin {
        display: flex;
        width: 684px;
        height: 482px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
    }

    .aq-list .aq-unlogin > img {
        margin-bottom: 100px;
        width: 451px;
        height: 273px;
    }

    .aq-list .aq-unlogin > .aq-unlogin-notice {
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
        color: #1B2122;
    }

    .aq-list .aq-unlogin > .aq-unlogin-notice > a {
        font-weight: 400;
        font-size: 18px;
        color: #FF8000;
    }
    /* 我的咨询 end */
</style>
